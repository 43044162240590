import React, { useEffect, useState, useRef, useMemo } from 'react'
import Image from 'next/image'
import styles from '../styles/Header.module.css'
import { useTranslation } from 'next-i18next'
import ChevronLeft from '../public/icons/leftArrow.svg'
import ChevronRight from '../public/icons/rightArrow.svg'

const ImageCarousel = () => {
  const { t, i18n } = useTranslation()
  const [images, setImages] = useState([])
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const [touchStartX, setTouchStartX] = useState(0)
  const [touchEndX, setTouchEndX] = useState(0)
  const [userInteracted, setUserInteracted] = useState(false)
  const carouselRef = useRef(null)

  useEffect(() => {
    const importAll = (r) => {
      return r.keys().map((item) => ({
        src: r(item).default,
        alt: item.replace('./', ''),
      }))
    }

    const imageContext = require.context('../public/img', false, /\.webp$/)
    const allImages = importAll(imageContext)
    setImages(allImages)
  }, [])

  // useEffect(() => {
  //   let timeoutId
  //   if (!userInteracted) {
  //     timeoutId = setTimeout(goToNextSlide, 4000)
  //   }
  //   return () => clearTimeout(timeoutId)
  // }, [currentImageIndex, userInteracted])

  const handleTouchStart = (e) => {
    setUserInteracted(true)
    setTouchStartX(e.touches[0].clientX)
  }

  const handleTouchMove = (e) => {
    setUserInteracted(true)
    setTouchEndX(e.touches[0].clientX)
  }

  const handleTouchEnd = () => {
    setUserInteracted(true)
    const distance = touchStartX - touchEndX
    if (distance > 50) {
      // Swiped left
      goToNextSlide()
    } else if (distance < -50) {
      // Swiped right
      goToPreviousSlide()
    }
    setTouchStartX(0)
    setTouchEndX(0)
  }

  const goToPreviousSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    )
  }

  const goToNextSlide = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    )
    setUserInteracted(false)
  }

  const uniqueImages = useMemo(() => {
    const uniqueSet = new Set()
    return images.filter((image) => {
      if (uniqueSet.has(image.src)) {
        return false // Skip if already added
      } else {
        uniqueSet.add(image.src)
        return true
      }
    })
  }, [images])

  // Ensure currentImageIndex stays within range
  useEffect(() => {
    if (currentImageIndex >= uniqueImages.length) {
      setCurrentImageIndex(0)
    }
  }, [currentImageIndex, uniqueImages.length])

  return (
    <section
      className={styles.carousel}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      ref={carouselRef}
    >
      <div className={styles.imageInfo}>
        <p className={styles.paginationNumber}>
          {currentImageIndex + 1}/{uniqueImages.length}
        </p>
      </div>
      <figure className={styles.imageCardBg}>
        {uniqueImages.map((image, index) => (
          <div
            key={index}
            className={`${styles.slide} ${
              index === currentImageIndex ? styles.active : ''
            }`}
          >
            <Image
              src={image.src}
              alt={image.alt}
              aria-label={image.alt || 'Image Not Available'}
              layout='fill'
              objectFit='cover'
              priority={index === 0} // Priority for the first image
              placeholder='blur' // Use blur-up placeholder
              sizes='(max-width: 600px) 100vw, 50vw' // Responsive sizes
              formats={['image/webp']}
            />
          </div>
        ))}
      </figure>
      <button className={styles.prevButton} onClick={goToPreviousSlide}>
        <Image
          src={ChevronLeft}
          alt={'chevronLeft icon'}
          width={30}
          height={30}
        />
      </button>
      <button className={styles.nextButton} onClick={goToNextSlide}>
        <Image
          src={ChevronRight}
          alt={'chevronLeft icon'}
          width={30}
          height={30}
        />
      </button>
    </section>
  )
}
export default ImageCarousel
